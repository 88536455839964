@import '~antd/dist/antd.less';

html{
    font-size:13vw;
    height:100%;
}

@media screen and (orientation: landscape) {
    html{
        font-size:13vh;
    }
}

body{
    margin:0;
    padding:0;
    height:100%;
}

h1,h2,h3,h4,h5,h6,p,ul{
    margin:0;
    padding:0;
}

*,*::after,*::before{
    box-sizing: border-box;
}

#root,.height{
    height:100%;
}

iframe{
    display: none;
}

input,textarea{
    outline-style: none;
}

li{
    list-style: none;
}

.flex{display:flex;}
.flex-v{flex-direction:column;}
.flex-1{flex:1;min-width:0;}
.flex-x{justify-content:center;}
.flex-y{align-items:center;}
.flex-w{flex-wrap: wrap;}

.ant-select-selector{
    height:auto !important;
    font-size:0.16rem;
    padding:4px 11px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after{
    line-height:inherit !important;
}

.ant-select-selection-item{
    line-height:inherit !important;
}

.ant-input-number-input{
    height:28px !important;
}

button{
    outline:none;
}
@primary-color: #1DA57A;